<h1 mat-dialog-title>{{ 'common.apply.model' | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <div class="box-border w-full">
        <div class="mat-subtitle-2">
            {{ 'common.search' | translate }}
        </div>
        <sz-input
            type="text"
            [formControl]="filterControl"
            (keyup)="filterModels()"
            (change)="filterModels()"
        />
    </div>
    <cdk-virtual-scroll-viewport
        itemSize="48"
        class="h-[200px] max-h-[300px] min-h-[100px]"
    >
        <mat-selection-list #select [multiple]="false">
            <mat-list-option
                class="mb-1.5 h-auto max-w-[370px] !rounded-md"
                *ngFor="let model of filteredModels"
                (click)="selectedModel = model"
                [class.selected]="model === selectedModel"
                [selected]="model === selectedModel"
            >
                <div class="flex flex-row items-center gap-3">
                    <div class="box-border px-0 py-0.5">
                        <sz-image
                            alt="product image"
                            class="h-10 w-10"
                            classList="rounded-full"
                            [src]="productImagePaths[model.id]"
                        />
                    </div>
                    <div class="truncate">
                        {{ model.name }}
                    </div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </cdk-virtual-scroll-viewport>
    <sz-dialog-button-bar
        [primaryDisabled]="!selectedModel"
        (primaryClick)="dialogRef.close(selectedModel)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
