import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/dynamic-api';
import { UserRole } from 'src/app/services/constants';
import { User } from 'src/app/shared/models/user';

@Component({
    templateUrl: './select-user-dialog.component.html',
})
export class SelectUserDialog implements OnInit {
    destroyRef = inject(DestroyRef);

    pinnedUsers: number[] = [];
    users: User[] = [];
    filterControl = new FormControl('');
    userId: number = null;

    get filterTerm() {
        return this.filterControl.value;
    }

    get userSelected() {
        return Boolean(this.userId);
    }

    constructor(
        public dialogRef: MatDialogRef<SelectUserDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private api: ApiService,
    ) {}

    async ngOnInit() {
        await ensureLoaded([this.api.pins, this.api.users]);

        this.api.users
            .listen({ sort: 'firstName', sortOrder: 'ASC' })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((users) => {
                this.users = users.filter(
                    (user) => user.userRole !== UserRole.Disabled,
                );
            });

        this.api.pins
            .listen({ type: 'user' })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pins) => {
                this.pinnedUsers = pins
                    .sort((a, b) => a.order - b.order)
                    .map((p) => p.itemId);
                this.updateUserList();
            });
    }

    updateUserList() {
        const sortedUsers = this.users.sort((a, b) => {
            const aIsPinned = this.pinnedUsers?.includes(a.id);
            const bIsPinned = this.pinnedUsers?.includes(b.id);
            if (aIsPinned && bIsPinned) {
                // Sort pinned users by their order.
                return (
                    this.pinnedUsers.indexOf(a.id) -
                    this.pinnedUsers.indexOf(b.id)
                );
            } else if (aIsPinned !== bIsPinned) {
                // Sort pinned users first.
                return aIsPinned ? -1 : 1;
            } else {
                return a.firstName.localeCompare(b.firstName);
            }
        });

        this.users = sortedUsers;
    }

    confirm() {
        const selectedUser = this.users.find((user) => user.id === this.userId);
        this.dialogRef.close(selectedUser);
    }
}
