import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/dynamic-api';
import { urlBaseV2 } from 'src/app/services/constants';
import { isDefined } from 'src/app/shared/common';
import { Model } from 'src/app/shared/models/model';

@Component({
    selector: 'app-apply-model-dialog',
    templateUrl: './apply-model-dialog.component.html',
})
export class ApplyModelDialog implements OnInit {
    allModels: Model[] = [];
    filterControl = new FormControl('');
    filteredModels: Model[] = [];
    selectedModel: Model;
    productImagePaths: Record<number, string> = {};

    get filterTerm() {
        return this.filterControl.value;
    }

    constructor(
        private api: ApiService,
        public dialogRef: MatDialogRef<ApplyModelDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: { models: Model[]; modelId: number },
    ) {}

    async ngOnInit() {
        await ensureLoaded([this.api.models]);
        this.allModels = this.filteredModels =
            this.data?.models ?? this.api.models.current();
        for (const model of this.allModels) {
            this.productImagePaths[model.id] =
                urlBaseV2 + '/models/' + model.id + '/thumbnail';
        }
        if (isDefined(this.data?.modelId)) {
            this.selectedModel =
                this.allModels.find((m) => m.id === this.data.modelId) ?? null;
        }
    }

    filterModels() {
        this.filteredModels = this.filterTerm
            ? this.allModels.filter((model) =>
                  model.name
                      .toLowerCase()
                      .includes(this.filterTerm.toLowerCase()),
              )
            : this.allModels;
    }
}
