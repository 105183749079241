<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <mat-hint class="flex justify-start">
        {{ data.description | translate }}
    </mat-hint>
    <sz-input [formControl]="filterControl" />
    <div class="h-72 overflow-auto">
        <mat-selection-list
            [multiple]="false"
            (selectionChange)="userId = $event.options[0].value"
        >
            <mat-list-option
                *ngFor="
                    let user of users | search: filterTerm : ['contactFullName']
                "
                [checkboxPosition]="'before'"
                [value]="user.id"
                [selected]="userId === user.id"
                class="!h-16 !rounded-md"
            >
                <div
                    class="flex max-w-[270px] h-10 flex-row items-center justify-between gap-2 px-2"
                >
                    <div class="truncate flex-grow-0 flex-shrink">
                        <span>{{ user.contactFullName }}</span>
                    </div>
                    @if (pinnedUsers.includes(user.id)) {
                        <div class="flex items-center">
                            <mat-icon
                                class="!h-5 !w-5 text-[goldenrod]"
                                svgIcon="pin"
                            />
                        </div>
                    }
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
    <sz-dialog-button-bar
        primaryText="{{ data.submitLabel | translate }}"
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
