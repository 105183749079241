import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { getAccessToken } from 'src/app/shared/auth-utils';

@Component({
    templateUrl: './new-password-dialog.component.html',
})
export class NewPasswordDialog {
    @Input() dialogType: string;
    title: string;
    type;
    token;

    form = new FormGroup({
        confirmPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(8),
        ]),
    });

    constructor(
        public dialogRef: MatDialogRef<NewPasswordDialog>,
        private snackBar: SnackBarService,
        private router: Router,
        private api: ApiService,
        @Inject(MAT_DIALOG_DATA)
        public data: { dialogType: string; token: string },
    ) {
        this.type = data.dialogType;
        switch (this.type) {
            case 'update':
                this.title = 'password.new';
                this.token = getAccessToken();
                break;
            case 'reset':
                this.title = 'password.reset';
                this.token = data.token;
                break;
        }
    }

    get new() {
        return this.form.get('newPassword');
    }

    get confirm() {
        return this.form.get('confirmPassword');
    }

    async updatePassword() {
        try {
            const newPassword = await this.api.users.updatePassword({
                password: this.new.value,
                access_token: this.token,
            });
            if (!newPassword) {
                return;
            }
            this.snackBar.open('password.reset.success');
            sessionStorage.setItem('resetPassword', '0');
            if (this.type === 'reset') {
                this.router.navigate(['reset-password-success']);
            }
            this.dialogRef.close();
        } catch ({ error }) {
            let text = '';
            if (error.message === 'Password matches.') {
                text = 'password.reset.error.matches';
            } else if (error.message === 'Token Expired') {
                text = 'password.reset.error.token_expired';
            } else {
                text = 'password.reset.error.could_not_be_updated';
            }
            this.snackBar.open(text);
        }
    }

    cancel() {
        this.dialogRef.close();
        this.router.navigate(['']);
    }

    hasUpper() {
        return /[A-Z]/.test(this.new.value);
    }

    hasLower() {
        return /[a-z]/.test(this.new.value);
    }

    hasNumber() {
        return /\d/.test(this.new.value);
    }

    hasSpecial() {
        return /\W/.test(this.new.value);
    }

    isMatch() {
        return this.new.value === this.confirm.value;
    }

    getRequirements() {
        let count = 0;
        if (this.hasLower()) {
            count++;
        }
        if (this.hasUpper()) {
            count++;
        }
        if (this.hasNumber()) {
            count++;
        }
        if (this.hasSpecial()) {
            count++;
        }

        return count >= 3 ? true : false;
    }
}
