<h1 mat-dialog-title>{{ title | translate }}</h1>
<div mat-dialog-content class="mat-typography" [formGroup]="form">
    <div class="flex flex-col gap-2">
        <div class="flex flex-col gap-1">
            <div class="mat-subtitle-2">
                {{ 'password.new' | translate }}
            </div>
            <sz-input type="password" formControlName="newPassword" />
        </div>
        <div class="flex flex-col gap-1">
            <div class="mat-subtitle-2">
                {{ 'password.confirm' | translate }}
            </div>
            <sz-input type="password" formControlName="confirmPassword" />
        </div>
        <div class="ml-4 col-md-12">
            {{ 'password.reset.description.part.1' | translate }}
            <span
                [ngClass]="{
                    'text-red-500': !isMatch(),
                    'text-green-700 dark:text-green-300':
                        isMatch() && !form.pristine,
                    'text-gray-700 dark:text-gray-300': form.pristine
                }"
                >{{ 'password.reset.description.part.2' | translate }}</span
            >{{ 'password.reset.description.part.3' | translate }}
            <span
                [ngClass]="{
                    'text-red-500': new.hasError('minlength'),
                    'text-green-700 dark:text-green-300':
                        !new.hasError('minlength') && !form.pristine,
                    'text-gray-700 dark:text-gray-300': form.pristine
                }"
                >{{ 'password.reset.description.part.4' | translate }}</span
            >{{ 'password.reset.description.part.5' | translate }}
            <br />
            <ul>
                <li
                    [ngClass]="{
                        'text-red-500': !hasUpper() && !form.pristine,
                        'text-green-700 dark:text-green-300':
                            hasUpper() && !form.pristine,
                        'text-gray-700 dark:text-gray-300': form.pristine
                    }"
                >
                    {{ 'password.reset.warning.upper' | translate }}
                </li>
                <li
                    [ngClass]="{
                        'text-red-500': !hasLower() && !form.pristine,
                        'text-green-700 dark:text-green-300':
                            hasLower() && !form.pristine,
                        'text-gray-700 dark:text-gray-300': form.pristine
                    }"
                >
                    {{ 'password.reset.warning.lower' | translate }}
                </li>
                <li
                    [ngClass]="{
                        'text-red-500': !hasNumber() && !form.pristine,
                        'text-green-700 dark:text-green-300':
                            hasNumber() && !form.pristine,
                        'text-gray-700 dark:text-gray-300': form.pristine
                    }"
                >
                    {{ 'password.reset.warning.number' | translate }}
                </li>
                <li
                    [ngClass]="{
                        'text-red-500': !hasSpecial() && !form.pristine,
                        'text-green-700 dark:text-green-300':
                            hasSpecial() && !form.pristine,
                        'text-gray-700 dark:text-gray-300': form.pristine
                    }"
                >
                    {{ 'password.reset.warning.special_character' | translate }}
                </li>
            </ul>
        </div>
    </div>
    <sz-dialog-button-bar
        id="new-password-button-bar"
        primaryColor="base"
        [primaryText]="type === 'update' ? 'btn.update' : 'password.reset'"
        [primaryDisabled]="!getRequirements() || !isMatch() || new.invalid"
        (primaryClick)="updatePassword()"
        (secondaryClick)="type === 'update' ? dialogRef.close() : cancel()"
    />
</div>
