import { NgModule } from '@angular/core';
import { NgxColorsModule } from 'ngx-colors';
import { CommonModule } from '../common/common.module';
import { AppMaterialModule } from '../services/app-material/app-material.module';
import { SettingItemComponent } from './setting-item/setting-item.component';
import { SettingsComponent } from './settings/settings.component';

@NgModule({
    imports: [AppMaterialModule, CommonModule, NgxColorsModule],
    declarations: [SettingsComponent, SettingItemComponent],
    exports: [SettingsComponent, SettingItemComponent],
})
export class SettingsModule {}
